export const athena = [
  "View upcoming medical appointments",
  "Get post-visit summaries",
  "Communicate with your medical team",
]

export const collectly = ["Pay medical bills"]

export const cr = ["View ABA appointments"]

export const telehealth = [
  "Doctors visits and other medical appointments",
  "Occupational, Physical, Speech and Music Therapies",
  "Counseling",
  "Parent Education",
  "ABA Sessions",
]

export const heading =
  "The Cortica Patient Portal makes it easy to view your complete schedule, including:"
