import React from "react"
import Card from "src/templates/current-patients-template/card.jsx"
import { useStaticQueryGetSiteFooter } from "src/services/graphQl/queries/footer"
import { useStaticQueryGetSiteHeader } from "src/services/graphQl/queries/header"
import Footer from "src/components/sections/footer"
import Header from "src/components/sections/header"
import Athena from "../../../static/images/athena.webp"
import Telehealth from "../../../static/images/portal.png"
import Collectly from "../../../static/images/collectly.png"
import { athena, collectly, telehealth, heading } from "./portalsData"
import "./for-patients.scss"

export default function ForPatients() {
  const footer = useStaticQueryGetSiteFooter()
  const header = useStaticQueryGetSiteHeader()

  return (
    <>
      <Header {...header} />
      <div className="hero-background">
        <p>For Current Patients</p>
      </div>
      <div className="container">
        <div className="cards-container">
          <Card
            alt="Cortica Patient Portal"
            heading={heading}
            href="https://portal.corticacare.com/"
            isTopCard={true}
            list={telehealth.map((listItems, index) => (
              <li key={index}>{listItems}</li>
            ))}
            portal="Cortica Patient Portal"
            src={Telehealth}
          />
        </div>
        <div className="cards-container">
          <Card
            alt="Athena"
            href="https://14973-8.portal.athenahealth.com/"
            list={athena.map((listItems, index) => (
              <li key={index}>{listItems}</li>
            ))}
            portal="Athena Health"
            src={Athena}
          />
          <Card
            alt="Collectly"
            href="https://pay.collectly.co/login"
            list={collectly.map((listItems, index) => (
              <li key={index}>{listItems}</li>
            ))}
            portal="Collectly"
            src={Collectly}
          />
        </div>
        <div className="prefooter">
          <p>
            Should you have any questions about your bill, please call us at{" "}
            <a href="tel:888-214-4826">(888) 214-4826</a> or email{" "}
            <a href="mailto:billing@corticacare.com">billing@corticacare.com</a>
          </p>
          <p>
            Looking for Central Reach?{" "}
            <a href="https://login.centralreach.com/login">Click here</a>
          </p>
        </div>
      </div>
      <Footer {...footer} />
    </>
  )
}
