import React from "react"
import PropTypes from "prop-types"
import "./for-patients.scss"

const Card = ({ alt, heading, href, isTopCard, list, portal, src }) => {
  const cardClass = `portal-card ${isTopCard ? "top-card" : ""}`

  return (
    <div className={cardClass}>
      {isTopCard ? (
        <div className="top-card-layout">
          <div className="portal-card-details">
            <div className="portal-title">
              <h3>{portal}</h3>
            </div>
            <div className="portal-heading">
              <div>{heading}</div>
            </div>
            <div className="list-items">
              <ul>
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="button-container">
              <button>
                <a href={href} rel="noreferrer" target="_blank">
                  Login
                </a>
              </button>
            </div>
          </div>
          <div className="portal-img-container">
            <img alt={alt} src={src} />
          </div>
        </div>
      ) : (
        <>
          <div className="portal-card-details">
            <div className="portal-title">
              <h3>{portal}</h3>
            </div>
            <div className="portal-img-container">
              <img alt={alt} src={src} />
            </div>
            <div className="list-items">
              <ul>
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="button-container">
            <button>
              <a href={href} rel="noreferrer" target="_blank">
                Login
              </a>
            </button>
          </div>
        </>
      )}
    </div>
  )
}
Card.propTypes = {
  alt: PropTypes.string,
  heading: PropTypes.string,
  href: PropTypes.string,
  isTopCard: PropTypes.bool,
  list: PropTypes.array,
  portal: PropTypes.string,
  src: PropTypes.string,
}
export default Card
